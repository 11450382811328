import React from 'react';
import { Link } from 'react-router-dom'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog-details/author.jpg'
import gl1 from '../../images/blog/2.jpg'
import gl2 from '../../images/blog/1.jpg'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'
import BlogSidebar from '../../components/BlogSidebar'
import './somestyle.css';

const BlogSingle = (props) => {

    const { id } = useParams()

    const BlogDetails = blogs.find(item => item.id === id)

    const submitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={BlogDetails.blogSingleImg} alt="" />
                                </div>
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"></i> By <Link to="/blog-single/1">{BlogDetails.author}</Link> </li>
                                        <li><i className="fi flaticon-comment-white-oval-bubble"></i> Commentss {BlogDetails.comment}</li>
                                        <li><i className="fi flaticon-calendar"></i> {BlogDetails.create_at}</li>
                                    </ul>
                                </div>
                                <h2>{BlogDetails.title}</h2>
                                {/* <p>{BlogDetails.desc1}</p> */}
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.desc1 }} />
                                <blockquote>
                                {BlogDetails.blockquote ? (BlogDetails.blockquote) : `Discover Dapoli's charm, where every corner unfolds new adventures and unforgettable experiences. Dive into a world of endless possibilities – explore, savor, and create cherished memories.`}
                                </blockquote>
                                <h3>
                                {BlogDetails.title1}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title1para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content1 }} />
                                <p>{BlogDetails.title1para2}</p>
                                <h3>
                                {BlogDetails.title2}
                                </h3>
                                <div className='divcontentfort2' dangerouslySetInnerHTML={{ __html: BlogDetails.divcontent }} />
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title2para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content2 }} />
                                <p>{BlogDetails.title2para2}</p>
                                <h3>
                                {BlogDetails.title3}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title3para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content3 }} />
                                <p>{BlogDetails.title3para2}</p>
                                {/* <p></p> */}
                                <h3>
                                {BlogDetails.title4}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title4para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content4 }} />
                                <p>{BlogDetails.title4para2}</p>
                                <p>{BlogDetails.title4para3}</p>
                                <h3>
                                {BlogDetails.title5}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title5para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content5 }} />
                                <p>{BlogDetails.title5para2}</p>
                                {/* <p>There are plenty of activities that you can do at the beaches in and around Dapoli. Some of the popular ones include swimming, sunbathing, and water sports such as parasailing, banana boat rides, and jet skiing. You can also take long walks on the beach, enjoy the sunset, and explore nearby attractions such as sea forts and temples.</p> */}
                                <h3>
                                {BlogDetails.title6}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title6para1 }} />
                                {/* <p>Dapoli is blessed with numerous natural wonders that offer visitors a chance to explore and connect with nature. Here are some of the top natural wonders in and around Dapoli:</p> */}
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content6 }} />
                                <p>{BlogDetails.title6para2}</p>
                                <h3>
                                {BlogDetails.title7}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title7para1 }} />
                                <ul className='blogul' dangerouslySetInnerHTML={{ __html: BlogDetails.title1_content7 }} />
                                <p>{BlogDetails.title7para2}</p>
                                <p>{BlogDetails.title7para3}</p>
                                <h3>
                                {BlogDetails.title8}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title8para1 }} />
                                <p>{BlogDetails.title8para2}</p>
                                <h3>
                                {BlogDetails.title9}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title9para1 }} />
                                <p>{BlogDetails.title9para2}</p>
                                <h3>
                                {BlogDetails.title10}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title10para1 }} />
                                <p>{BlogDetails.title10para2}</p>
                                <h3>
                                {BlogDetails.title11}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title11para1 }} />
                                <p>{BlogDetails.title11para2}</p>
                                <h3>
                                {BlogDetails.title12}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title12para1 }} />
                                <p>{BlogDetails.title12para2}</p>
                                <h3>
                                {BlogDetails.title13}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title13para1 }} />
                                <p>{BlogDetails.title13para2}</p>
                                <h3>
                                {BlogDetails.title14}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title14para1 }} />
                                <p>{BlogDetails.title14para2}</p>
                                <h3>
                                {BlogDetails.title15}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: BlogDetails.title15para1 }} />
                                <p>{BlogDetails.title15para2}</p>
                                <div className="gallery">
                                    <div>
                                    {BlogDetails.img1 ? <img src={BlogDetails.img1} alt="" /> : <img src={gl1} alt="" />} 
                                    </div>
                                    <div>
                                    {BlogDetails.img2 ? <img src={BlogDetails.img2} alt="" /> : <img src={gl2} alt="" />} 
                                        {/* <img src={gl2} alt="" /> */}
                                    </div>
                                    </div>
                                    <div className="gallery">
                                    <div>
                                    {BlogDetails.img3 ? <img src={BlogDetails.img3} alt="" /> : null} 
                                    </div>
                                    <div>
                                    {BlogDetails.img4 ? <img src={BlogDetails.img4} alt="" /> : null} 
                                    </div>
                                </div>
                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <span>Share: </span>
                                    <ul>
                                        <li><Link to="/blog-single/1">Destination</Link></li>
                                        <li><Link to="/blog-single/1">Travelling</Link></li>
                                        <li><Link to="/blog-single/1">World Tour</Link></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="tag-share-s2 clearfix">
                                <div className="tag">
                                    <span>Share: </span>
                                    <ul>
                                        <li><Link to="/blog-single/1">facebook</Link></li>
                                        <li><Link to="/blog-single/1">twitter</Link></li>
                                        <li><Link to="/blog-single/1">linkedin</Link></li>
                                        <li><Link to="/blog-single/1">pinterest</Link></li>
                                    </ul>
                                </div>
                            </div> */}

                            {/* <div className="author-box">
                                <div className="author-avatar">
                                    <Link to="/blog-single/1" target="_blank"><img src={blog6} alt="" /></Link>
                                </div>
                                <div className="author-content">
                                    <Link to="/blog-single/1" className="author-name">Author: {BlogDetails.author}</Link>
                                    <p>{BlogDetails.authorremark}</p>
                                    <div className="socials">
                                        <ul className="social-link">
                                            <li><Link to="/blog-single/1"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-linkedin"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="more-posts">
                                <div className="previous-post">
                                    <Link to="/blog">
                                        <span className="post-control-link">Previous Post</span>
                                        <span className="post-name">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-left-sidebar">
                                        <span className="post-control-link">Next Post</span>
                                        <span className="post-name">Dignissimos ducimus qui blanditiis praesentiu deleniti atque corrupti quos dolores</span>
                                    </Link>
                                </div>
                            </div> */}

                            {/* <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2022
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Lily Watson <span className="comments-date">January
                                                                        12,2022 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>I will give you a complete account of the system,
                                                                        and expound the actual teachings of the great
                                                                        explorer of the truth, </p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>John Abraham <span className="comments-date">January
                                                                                12,2022 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>I will give you a complete account of the
                                                                                system, and expound the actual teachings
                                                                                of the great explorer of the truth, </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="comment">
                                            <div>
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2022
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Post Comments</h3>
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input placeholder="Website" type="url" />
                                            <input placeholder="Name" type="text" />
                                            <input placeholder="Email" type="email" />
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} details={BlogDetails} />
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
